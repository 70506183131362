

.react-geocoder {
  width: 400px; 
  font-size: 18px;
  line-height: 24px;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, Helvetica, sans-serif;
  position: relative;
  background-color: #fff;
  width: 100%;
  min-width: 300px;
  z-index: 1;
  border-radius: 0px;
  transition: width .25s, min-width .25s;  
  border: 1px solid #404040;
}

.react-geocoder input {
  font: inherit;
  width: 100%;
  border: 0;
  background-color: white;
  margin: 0;
  height: 50px;
  color: #404040; 
  padding: 6px;
  padding-right: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;  
}

.react-geocoder-results {
  position: relative;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: white;
  border: 0px solid black;
  width: 100%;
  padding: 6px
}

.react-geocoder-item {
  border-bottom: 1px solid #404040;
  padding: 2px;
}

.react-geocoder-item:hover{ 
  cursor:pointer
}